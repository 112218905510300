#overlay {
    position: fixed;
    inset: 0;
    background: linear-gradient(
            to bottom,
            black,
            transparent 2%,
            transparent 98%,
            black
        ),
        linear-gradient(
            to right,
            black,
            transparent 2%,
            transparent 98%,
            black
        );
    z-index: 20;
}
