* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    font-family: "Segoe UI", sans-serif;
    min-height: 100vh;
    background-color: black;
    overflow: hidden;
    font-family: Consolas, monospace;
    color: white;
}
#github {
  position: fixed;
  z-index: 200;
  opacity: 0.8;
  right: 0;
}
